import React from "react";
import { useTranslation } from "react-i18next";
import { graphql } from 'gatsby';
import { StructuredText } from 'react-datocms';
import { GatsbyImage } from "gatsby-plugin-image"

import ContentBlock from "../components/02_molecules/ContentBlock/ContentBlock";
import ContentBlockItem from "../components/02_molecules/ContentBlock/ContentBlockItem";

import * as pages from '../components/03_organisms/Pages/pages.module.scss';
import Section from "../components/03_organisms/Section/Section";
import { BreadcrumbItem } from "../components/02_molecules/Breadcrumb/BreadcrumbItem";
import { Breadcrumb } from "../components/02_molecules/Breadcrumb/Breadcrumb";
import { Helmet } from "react-helmet";
import { Heading } from "../components/01_atoms/Heading/Heading";
import { Hero } from "../components/03_organisms/Hero/Hero";
import { Quicklinks } from "../components/03_organisms/Quicklinks/Quicklinks";
import Button from "../components/01_atoms/Button/Button";
import { HelmetDatoCms } from 'gatsby-source-datocms';
import { HeadInject } from "../components/03_organisms/HeadInject/HeadInject";


const JobDetail = ({data,pageContext, ...props }) => {

	// read language
	const {t} = useTranslation();

	// render html
	return (
		<>
			<HeadInject />
			<Helmet bodyAttributes={{ class: pages.detail }} />
			<HelmetDatoCms seo={data.datoCmsJob.seoMetaTags} />

			<Section content={false}>
				<Breadcrumb>
					<BreadcrumbItem to="/jobs" title="Jobs" />
					<BreadcrumbItem to={`/jobs/${data.datoCmsJob.slug}`} title={data.datoCmsJob.title} active />
				</Breadcrumb>
			</Section>

			<Section>
				<Heading element="h1" size="xxl" align="center">{data.datoCmsJob.title}</Heading>
			</Section>

			<Hero
				image={data.datoCmsJob.hero[0].heroImage}
				alt={data.datoCmsJob.hero[0].heroImage.alt ? data.datoCmsJob.hero[0].heroImage.alt : null }
				video={data.datoCmsJob.hero[0].heroVideo ? data.datoCmsJob.hero[0].heroVideo : null }
			/>



			<Section>
				{data.datoCmsJob.contentBlocks.map((contentBlock,index) => (
						<ContentBlock key={index} positionMediaLeft={contentBlock.positionMediaLeft} blockStyling={contentBlock.blockStyling} fullwidth={contentBlock.fullwidth} >
							<ContentBlockItem>
										<StructuredText data={contentBlock.title}></StructuredText>
										<StructuredText data={contentBlock.text}></StructuredText>
								</ContentBlockItem>
								<ContentBlockItem>
										{ contentBlock.image ?  <GatsbyImage image={contentBlock.image.gatsbyImageData} alt={contentBlock.image.alt ? contentBlock.image.alt : ""}></GatsbyImage> : null }
								</ContentBlockItem>
						</ContentBlock>
				))}
			</Section>

			<Quicklinks title={t('quicklinks_job_title')} background="yellow">
				<Button exteral={true} arrow={false} to="https://www.linkedin.com/company/ddb-brussels">
					{t('quicklinks_job_cta')}
					<svg width="28" height="28" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M21.875.5H6.125A5.626 5.626 0 00.5 6.125v15.75A5.626 5.626 0 006.125 27.5h15.75a5.625 5.625 0 005.625-5.625V6.125A5.625 5.625 0 0021.875.5zM9.5 21.875H6.125V9.5H9.5v12.375zM7.812 8.073A1.977 1.977 0 015.845 6.09c0-1.096.882-1.985 1.968-1.985 1.087 0 1.97.89 1.97 1.985 0 1.096-.882 1.984-1.97 1.984zM23 21.875h-3.375V15.57c0-3.789-4.5-3.502-4.5 0v6.305H11.75V9.5h3.375v1.986C16.695 8.576 23 8.36 23 14.27v7.604z" fill="#212322"/>
					</svg>
					</Button>
				<Button exteral={true} arrow={false} to="mailto:maarten.vanthemsche@ddb.be">
					{t('quicklinks_job_cta')}
					<svg width="36" height="26" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M3.868 0C1.73 0 0 1.612 0 3.535v18.93C0 24.387 1.73 26 3.868 26h28.264C34.27 26 36 24.387 36 22.465V3.535C36 1.612 34.27 0 32.132 0H3.868zm.923 2.334c9.121.022 18.242-.034 27.363.004L18.043 13.436 4.79 2.333zM17.104 15.86a1.41 1.41 0 001.754.035L33.394 4.468v17.997c0 .699-.555 1.192-1.262 1.192H3.868c-.707 0-1.261-.493-1.261-1.192V3.535c4.56 3.638 9.788 8.279 14.497 12.326z" fill="#212322"/>
					</svg>
					</Button>
			</Quicklinks>




		</>

	)
}

export default JobDetail;

export const query = graphql`
	query JobQuery($slug: String!) {
		datoCmsJob(slug: { eq: $slug }) {
			seoMetaTags {
				...GatsbyDatoCmsSeoMetaTags
			}
		title
		slug
			hero {
				heroImage {
					gatsbyImageData
					alt
					format
					url
				}
				heroVideo {
					url
					height
					width	
				}
			}
		contentBlocks {
		... on DatoCmsTitleMediaText {
						blockStyling
						fullwidth
						text {
							value
						}
						title{
								value
						}
						image {
							gatsbyImageData
							alt
						}
						positionMediaLeft
				}
		}
		}
	}
`

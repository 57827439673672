import React from 'react';
import * as styles from './styles/_Section.module.scss';
import cx from 'classnames';

function Section({ children, content = true, fullwidth = false, background, zBackground,zIndex="20", classes, ...props }) {

    // define classes
    const classname = cx(
        styles.section,
        styles[`background_${background}`],
        styles[`zIndex_${zIndex}`],
        { [styles.hasContent]: content},
        { [styles.fullwidth]: fullwidth},
        styles[classes]
    );

    // render html
    return (
        <section className={classname}>
            <div className={styles.section__inner}>
                {children}
            </div>
        </section>
    );
}

export default Section;

import React from 'react';
import * as styles from './breadcrumb.module.scss';
import { Link } from "gatsby";
import cx from 'classnames';


export const BreadcrumbItem = ({children,title,to,active=false, ...props}) => {
    // render html

    // define classes
    const classname = cx(
        styles.item,
        { [`${styles.active}`]: active}
    );

    return (
        <>
            <Link to={to} className={classname}>
                {title}
            </Link>
            {!active ? <span className={styles.seperator}> / </span> : null}
        </>
    );


};
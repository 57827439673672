import React from 'react';
import * as styles from './breadcrumb.module.scss';
import { Link } from "gatsby";


export const Breadcrumb = ({children, ...props}) => {
    // render html

    return (
        <div className={styles.breadcrumb}>
            <Link to="/">
                <svg width="12" height="13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.589.13L.28 4.053A.704.704 0 000 4.615v7.154c0 .363.33.692.692.692h10.616A.72.72 0 0012 11.77V4.615a.702.702 0 00-.281-.562L6.41.13c-.287-.18-.546-.166-.822 0zM6 1.55l4.615 3.412v6.115h-9.23V4.962L6 1.55z" fill="#000"/>
                </svg>

            </Link>
            <span className={styles.seperator}> / </span>
            {children}
        </div>
    );


};